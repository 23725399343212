import React, { useState, useEffect } from "react";
import { Col, Nav, Row, Tab, Tabs } from "react-bootstrap";
import Breadcrumb from "../../component/breadcrumb";
import DownloadApp from "../../component/download-app/downloadApp";
import workflowmanagement from "../../assets/images/workflow-management.svg";
import careContinuum from "../../assets/images/care-continuum.svg";
import dataDriven from "../../assets/images/data-driven.svg";
import compliantMiddleware from "../../assets/images/compliant-middleware.svg";
import partnerone from "../../assets/images/partner-one.webp";
import partnerTwo from "../../assets/images/partner-two.webp";
import partnerthree from "../../assets/images/partner-three.webp";
import hospitalInformation from "../../assets/images/hospital-information.svg";
import patientEngagement from "../../assets/images/patient-engagement.svg";
import queueManagement from "../../assets/images/queue-management.svg";
import doctorNurses from "../../assets/images/doctor-nurses.svg";
import remoteHealth from "../../assets/images/remote-health.svg";
import electronicMedical from "../../assets/images/electronic-medical.svg";
import experienceFeedback from "../../assets/images/experience-feedback.svg";
import registerFamilyMembers from "../../assets/images/register-family-members.svg";
import shareDocuments from "../../assets/images/share-documents.svg";
import orderMedicines from "../../assets/images/order-medicines-icon.svg";
import easyAndSecure from "../../assets/images/easy-and-secure.svg";
import telemedicine from "../../assets/images/telemedicine.svg";
import manageAppointments from "../../assets/images/manage-appointments.svg";
import homehealthCare from "../../assets/images/homehealth-care.svg";
import { getImageFromCMS, getTextFromCMS } from "../../actions/get-calls";
import { Link } from "react-router-dom";
import BreadcrumbWithUrl from "../../component/breadcrumb/breadcrumb-with-url";
import YourPartners from "./YourPartners";
import FourthSection from "./FourthSection";
import FifthSection from "./FifthSection";
import SixthSection from "./SixthSection";
import SeventhSection from "./SeventhSection";
import EigthSection from "./EigthSection";

const Partners = () => {
  const [content, setContent] = useState(null);
  const [contentAccordian, setContentAccordian] = useState(null);

  useEffect(async () => {
    
    const _setContentAccordian = await getTextFromCMS(3, 3);
    if (_setContentAccordian) setContentAccordian(_setContentAccordian.data);
  }, []);
  const breadCrumbData = [
    { type: 1, url: "/", name: "Home" },
    { type: 2, name: "For Partners" },
  ];
  return (
    <React.Fragment>
      
    <YourPartners />
      {contentAccordian ? (
        <section className="mhc-partner-bg mhc-partner-padding">
          <div className="container mhc-partner-tabs">
            <Tab.Container id="left-tabs-example" defaultActiveKey={0}>
              <Row className="justify-content-between">
                <Col md={4} lg={3}>
                  <Nav variant="pills" className="flex-column">
                    {contentAccordian.map((item, index) => {
                      return (
                        <Nav.Item key={item._id}>
                          <Nav.Link eventKey={index}>
                            <img
                              className="pils-icon"
                              src={
                                index == 0
                                  ? workflowmanagement
                                  : index == 1
                                  ? careContinuum
                                  : index == 2
                                  ? dataDriven
                                  : compliantMiddleware
                              }
                              alt={item.heading}
                            />{" "}
                            {item.heading}
                          </Nav.Link>
                        </Nav.Item>
                      );
                    })}
                  </Nav>
                </Col>
                <Col md={7} lg={8}>
                  <Tab.Content>
                    {contentAccordian.map((item, index) => {
                      return (
                        <Tab.Pane eventKey={index} key={item._id}>
                          <h2>{item.heading}</h2>
                          <div
                            dangerouslySetInnerHTML={{ __html: item.text }}
                          ></div>
                        </Tab.Pane>
                      );
                    })}
                  </Tab.Content>
                </Col>
              </Row>
            </Tab.Container>
          </div>
        </section>
      ) : (
        ""
      )}

        <FourthSection />
      <FifthSection />
      <SixthSection />
      <SeventhSection />
      <EigthSection />
      <DownloadApp />
    </React.Fragment>
  );
};

export default Partners;
