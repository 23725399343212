import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Select from "react-select";

import {
    getLatLongDetail,
  indianCities,
  pincodeByCity,
  searchindianCities,
} from "../../actions/get-calls";
import Location from "../../assets/images/mhc-location.svg";
import { locationReducer } from "../../redux/slice/location";
import { selectedCityReducer } from "../../redux/slice/selected-city";
import { selectedPincodeReducer } from "../../redux/slice/selected-pincode";

const MHCLocation = ({ isNavbar }) => {
  const dispatch = useDispatch();
  const locationData = useSelector((state) => state.location.value);
  const cityData = useSelector((state) => state.selectedCity.value);
  const [allCities, setAllCities] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [currentCityPage, setCurrentCityPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [searchedCity,setSearchedCity] = useState('')
  const [searchedValues,setSearchedValues] = useState([]);
  
  useEffect(() => {
    if (locationData && !cityData) {
      dispatch(selectedPincodeReducer(locationData.pin_code));
      dispatch(selectedCityReducer(locationData.city));
    }
    getCities();
  }, [locationData]);

  const customStyles = {
    container: () => ({
      width: "100%",
    }),
    control: () => ({
      borderColor: "#fff",
      display: "flex",
      background: "#fff",
      height: "100%",
      borderTopRightRadius: "10px",
      borderBottomRightRadius: "10px",
    }),
    dropdownIndicator: () => ({
      color: "#5c5c5c ",
      marginRight: "5px",
    }),
    indicatorSeparator: () => ({
      borderColor: "#fff",
    }),
    menuList: () => ({
      borderColor: "#fff",
      height: '200px',
      overflow: 'scroll'
    }),
  };

  const getCurrentLocation = (e)=>{
      e.preventDefault()
    console.log('e: ', e);
    setIsLoading(true)
    navigator.geolocation.getCurrentPosition(
        async (position) => {
            if (position && position.coords) {
                // setStatus(null);
            const _locationDetail = await getLatLongDetail(
                position.coords.latitude,
                position.coords.longitude
                );
                
            if (
              _locationDetail &&
              _locationDetail.data &&
              _locationDetail.data.results
              ) {
                  let cityDetail =  _locationDetail.data.results[0]
                  console.log('_locationDetail.data.results[0]: ', _locationDetail.data.results[0]);
                dispatch(locationReducer(_locationDetail.data.results[0]));

                  dispatch(selectedCityReducer({label:cityDetail.city,value:cityDetail.pin_code}));
                  
                  setIsLoading(false)
                  //   dispatch(locationReducer(_locationDetail.data.results[0]));
                }else{
                    
                    setIsLoading(false)
            }
          }
        },
        () => {
            setIsLoading(false)
        //   setStatus("Unable to retrieve your location");
        }
      );
  }
  const handleScrollToBottom = () => {
    setIsLoading(true);
    setCurrentCityPage(currentCityPage + 1);
    getCities();
  };
  async function getCities() {
    if (currentCityPage && hasMore) {
      const cityList = await indianCities(1, currentCityPage);
      // console.log('cityList: ', cityList);

      if (cityList && cityList.data) {
        setAllCities(
          [...allCities, ...cityList.data.cities].map((e) => ({
            ...e,
            label: e.name || e.label,
            value: e.id || e.value,
            latitude:e.lat,
            longitude:e.long,
          }))
        );
        setIsLoading(false);
        if (cityList.data.pagination.links.next) {
          let cPage = currentCityPage + 1;
          setCurrentCityPage(cPage);
          setHasMore(true);
        } else {
          setHasMore(false);
        }
      }
    }
  }
  async function searchCities(searchValue) {
    console.log("searchValue: ", searchValue);
    setSearchedCity(searchValue)
    if (searchValue && searchValue.length > 3) {
      console.log("HERE");
      setIsLoading(true);
      const cityList = await searchindianCities(searchValue);
      console.log("cityList: ", cityList);
      if(cityList && cityList.data && cityList.data.cities){
          setSearchedValues(cityList.data.cities.map((e)=>{
            return {
                ...e,
                label:e.name,
                value:e.id,
                latitude:e.lat,
                longitude:e.long,
            }
          }))
          
        }else{
            
            setSearchedValues([])
      }
      setIsLoading(false);
    }
  }
  return (
    <div
      className={`input-group flex-nowrap ${
        isNavbar === true ? "navlocation" : ""
      }`}
    >
      <button className="input-group-text bg-white border-0" onClick={getCurrentLocation}>
        <img
          className=""
          src={Location}
          alt="Location"
          style={{ height: "20px" }}
        />
      </button>
      <Select
        options={searchedCity?searchedValues :allCities}
        classNamePrefix="select"
        placeholder={locationData ? locationData.city : "Select City"}
        value={cityData ? cityData : null}
        onChange={(e) => {
          console.log("onChange",e);
          dispatch(locationReducer(e));

          dispatch(selectedCityReducer(e));
        }}
        // maxMenuHeight={200}
        styles={customStyles}
        isLoading={isLoading}      
        onMenuScrollToBottom={handleScrollToBottom}
        onInputChange={(val) => {
          console.log("val: ", val);
          searchCities(val);
        }}
      />
    </div>
  );
};

export default MHCLocation;
