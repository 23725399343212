import React, { useEffect, useLayoutEffect, useState } from "react";
import { Tabs, Tab } from "react-bootstrap";
import { useLocation } from "react-router-dom";

import Breadcrumb from "../../component/breadcrumb/index";
import Specialities from "../specialities/index";
import HospitalTab from "../hospitals/hospitalTab";
import ClinicsTab from "../clinics/clinicsTab";
import DoctorsTabs from "../DoctorsTab/doctorsTab";

import SearchTextWidget from "../../component/search/search-text-widget";
import BreadcrumbWithUrl from "../../component/breadcrumb/breadcrumb-with-url";

function BookingConsult() {
  const location = useLocation();
  const [callingType, setCallingType] = useState("hospitals");
  const [clinicList, setClinicList] = useState();

  const [keyword, setKeyword] = useState("");

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });
  const [radiobutton, setRadiobutton] = useState(1);
  const [tempKeyword,setTempKeyword] = useState('');
  useEffect(() => {
    let cType =
      location.pathname === "/book-consult"
        ? "hospitals"
        : location.pathname === "/book-consult/hospitals"
        ? "hospitals"
        : location.pathname === "/book-consult/specialities"
        ? "specialities"
        : location.pathname === "/book-consult/clinics"
        ? "clinics"
        : location.pathname === "/book-consult/doctors"
        ? "doctors"
        : "hospitals";

    setCallingType(cType);
  }, [location]);

  const breadCrumbData = [
    { type: 1, url: "/", name: "Home" },
    { type: 2, url: "", name: "Book Consult" },
    { type: 3, url: "", name: callingType },
  ];
  const handleRadioButton = (e) => {
    setRadiobutton(e.target.value);
  };
  const searchClickHandler = ()=>{
    if(keyword.length>2){
      setTempKeyword(keyword)
    }else if(keyword.length===0){
      setTempKeyword('')
    }
  }
  return (
    <React.Fragment>
      <section>
        <div className="container">
          <BreadcrumbWithUrl breadcrumbs={breadCrumbData} />

          <div className="row">
            <div
              className={`${
                callingType === "doctors" ? "col-lg-4" : "col-lg-6"
              } `}
            >
              <h1 className="fs-4 mb-1">Book Consult</h1>
              <p className="m-0 fs-6">
                Consult with top doctors across specialities
              </p>
            </div>
            {callingType === "doctors" && (
              <div className="col-lg-4">
                <ul className="list-unstyled mb-0 mt-4 mt-lg-0 d-flex align-items-center justify-content-start justify-content-lg-end">
                  <li>Search By</li>
                  <li>
                    {" "}
                    <div className="form-check mt-1 ms-0 ms-lg-4">
                      <input
                        className="form-check-input"
                        // value={1}
                        checked={radiobutton === 1}
                        defaultChecked={radiobutton == 1 ? true : false}
                        type="radio"
                        name="doctortype"
                        // id="Dector"
                        onChange={() => {
                          setRadiobutton(1);
                          setKeyword("");
                        }}
                        // onChange={(e) => handleRadioButton(e)}
                      />
                      <label className="form-check-label" htmlFor="Dector">
                        Doctor
                      </label>
                    </div>
                  </li>
                  <li>
                    {" "}
                    <div className="form-check mt-1 ms-3 ms-sm-4">
                      <input
                        className="form-check-input"
                        // value={2}
                        checked={radiobutton === 2}
                        type="radio"
                        name="doctortype"
                        defaultChecked={radiobutton == 2 ? true : false}
                        onChange={() => {
                          setRadiobutton(2);
                          setKeyword("");
                        }}

                        // id="Specialities"
                        // onChange={(e) => handleRadioButton(e)}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="Specialities"
                      >
                        Specialities
                      </label>
                    </div>
                  </li>
                </ul>
              </div>
            )}
            <div
              className={`${
                callingType === "doctors" ? "col-lg-4" : "col-lg-6"
              } mt-4 mt-lg-0`}
            >
              <SearchTextWidget
                placeHolder={`Search ${
                  callingType === "doctors"
                    ? radiobutton === 1
                      ? "Doctors"
                      : "Specialities"
                    : callingType === 'clinics'?'Clinics/Speciality':callingType==='hospitals'?'Hospitals':callingType==='specialities'?'Specialities':''
                }`}
                btnDisplay={true}
                radiobutton={radiobutton}
                setKeyword={setKeyword}
                searchClickHandler={searchClickHandler}
              />
            </div>
          </div>
          <div className="mhc-tabs my-4">
            <Tabs
              activeKey={callingType}
              onSelect={(e) => {
                setCallingType(e);
                setKeyword("");
                setTempKeyword("");
                window.history.replaceState(
                  {},
                  document.getElementsByTagName("title")[0].innerHTML,
                  `/book-consult/${e}`
                );
              }}
              className="mb-3"
            >
              <Tab eventKey="hospitals" title="Hospitals">
                <div className="mt-2">
                  <HospitalTab
                    keyword={tempKeyword}
                    callingType={callingType}
                  />
                </div>
              </Tab>

              <Tab eventKey="specialities" title="Specialities">
                <div className="mt-2">
                  <Specialities
                    keyword={tempKeyword}
                    callingType={callingType}
                  />
                </div>
              </Tab>

              <Tab eventKey="clinics" title="Clinics">
                <div className="mt-2">
                  <ClinicsTab
                    setClinicList={setClinicList}
                    callingType={callingType}
                    keyword={tempKeyword}
                  />
                </div>
              </Tab>

              <Tab eventKey="doctors" title="Doctors">
                <DoctorsTabs
                  keyword={tempKeyword}
                  type={radiobutton}
                  callingType={callingType}
                  bookingType={"doctors"}
                />
              </Tab>
            </Tabs>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}

export default BookingConsult;
