import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import { Accordion } from "react-bootstrap";
import {
  getAllSpecialities,
  specialitiesListByHospital,
} from "../../actions/get-calls";
import { encodeString } from "../../constants/app-constants";
import { searchTextAPI } from "../../actions/post-call";

const Specialities = ({ keyword, callingType }) => {
  const [specialities, setSpecialities] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [page,setPage] = useState(1);
  const [total,setTotal] = useState(0);
  const getSpecilities = useCallback(() => {
    setLoading(true);
    getAllSpecialities(page)
      .then(function (response) {
        console.log('response:============================ ', response);
        setTotal(response.data.pagination.total)
        setPage(2)
        setLoading(false);
        let res = response.data;
        setSpecialities(res.specialities);
      })
      .catch(function (err) {
        setLoading(false);
      });
  }, []);
  const loadMoreSpecialities=()=>{
    setLoadingMore(true);
    getAllSpecialities(page)
      .then(function (response) {
        console.log('response:============================ ', response);
        setTotal(response.data.pagination.total)
        setPage(page+1)
        setLoadingMore(false);
        let res = response.data;
        setSpecialities([...specialities ,...res.specialities]);
      })
      .catch(function (err) {
        setLoadingMore(false);
      });
  }
  useEffect(() => {
    getSpecilities();
  }, [getSpecilities]);
  const searchSpecilities = useCallback(() => {
    if (keyword === "" || keyword.length < 3) return false;
    try {
      const delayDebounceFn = setTimeout(async () => {
        setLoading(true);

        const searchedData = await searchTextAPI(keyword, "specialities");
        setLoading(false);

        if (searchedData.data.status === 200) {
          setSpecialities(searchedData.data.specialities);
        }
      }, 500);
      return () => clearTimeout(delayDebounceFn);
    } catch (error) {
      setLoading(false);
    }
  }, [keyword]);
  useEffect(() => {
    if (keyword && keyword.length > 2 && callingType === "specialities") {
      searchSpecilities();
    } 
  }, [keyword, callingType, searchSpecilities, getSpecilities]);
  useEffect(()=>{
    if(keyword.length===0){
      getSpecilities()

    }
  },[keyword])
  return (
    <>
      <Accordion defaultActiveKey="" className="specialitiestabs">
        <div className="row">
          {loading ? (
            <div className="col-lg-12">
              <div className="mhc-Loding text-center my-5">
                <img src="../loader.gif" alt="loader" />
              </div>
            </div>
          ) : (
            <>
              {specialities.map((item) => {
                return (
                  <div className="col-lg-4" key={item.id}>
                    <Accordion.Item eventKey={item.id} className="mb-4">
                      <Accordion.Header>
                        <Link
                          to={`/book-consult/specialities/${
                            item.slug
                          }/${item.id}`}
                        >
                          <img src={item.icon} alt={item.name} />
                          {item.name}
                        </Link>
                      </Accordion.Header>
                      <Accordion.Body>
                        {/* <ul className="list-unstyled">
                          {item.actions.map((item, index) => {
                            return (
                              <li key={index}><Link to={item.uri}>{item.name}</Link></li>
                            );
                          })}
                        </ul> */}
                      </Accordion.Body>
                    </Accordion.Item>
                  </div>
                );
              })}
            </>
          )}
          {loadingMore ? <div className="col-lg-12">
              <div className="mhc-Loding text-center my-5">
                <img src="../loader.gif" alt="loader" />
              </div>
            </div> :specialities.length !== total && keyword.length<2&& 
          <div style={{textAlign:'center',color:'#EA5D6E'}}>
          <p onClick={loadMoreSpecialities} style={{cursor:'pointer'}}>load more</p></div>}
        </div>
      </Accordion>
    </>
  );
};

export default Specialities;
