import React, { useState, useEffect, useRef } from "react";
import { Accordion, Button, Container, Modal } from "react-bootstrap";
import Breadcrumb from "../../component/breadcrumb/index";
import SearchForm from "../../component/search/index";
import Fliter from "../../assets/dust/fliter.svg";
import LodaingImage from "../../assets/images/loader.gif";

import { Link, useLocation, useParams } from "react-router-dom";
import {
  clinicDistance,
  doctorAvailability,
  doctorImmediateAvailability,
  getHospitals,
  indianCities,
} from "../../actions/get-calls";
import { specialityInfoById } from "../../actions/get-calls";
import Doctors from "./doctors";
import {
  doctorAvailabilityByFilter,
  searchTextAPI,
} from "../../actions/post-call";
import SearchFormWidget from "../../component/search/search-form-widget";
import {
  dateFormatIntoDDMMYYYY,
  decodeString,
} from "../../constants/app-constants";
import BreadcrumbWithUrl from "../../component/breadcrumb/breadcrumb-with-url";
import SearchTextWidget from "../../component/search/search-text-widget";
import FilterDoctorModal from "./FilterDoctorModal";

export default function ConsultDoctor() {
  const listInnerRef = useRef();
  const { hospitalName, Id, sId } = useParams();
  const [filterModal, setFilterModal] = useState(false);
  const [doctor, setDoctor] = useState([]);
  const [allDoctors, setAllDoctors] = useState([]);
  const [specialInfo, setSpecialInfo] = useState();
  const [consultType, setConsultType] = useState("0");

  const [keyword, setKeyword] = useState("");
  const [allDistances, setAllDistances] = useState(null);
  const [allCities, setAllCities] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [currentCityPage, setCurrentCityPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [cities, setCities] = useState([]);
  const [distance, setDistance] = useState(0);
  const [dateType, setDateType] = useState(0);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [gender, setGender] = useState(0);
  const [specialityName, setSpecialityname] = useState();
  const [availabilityType, setAvailibilityType] = useState("");
  const [filterApplied, setFilterApplied] = useState(false);
  const location = useLocation();
  const [hospitalInfo, setHospitalInfo] = useState(null);
  const [tempKeyword, setTempKeyoword] = useState('');

  console.log("location:===========>>>>>>>>>>> ", location);
  const searchButtonClick=()=>{
    if(keyword.length>3){
      setTempKeyoword(keyword)
    }else if(keyword.length==0){
      setTempKeyoword(keyword)
    }
  }
  useEffect(() => {
    getHospitals(Id)
      .then(function (response) {
        let res = response.data;
        setHospitalInfo(res.hospital);
      })
      .catch(function () {});
  }, []);
  useEffect(async () => {
    getDoctors();

    const specials = await specialityInfoById(sId);
    setSpecialInfo(specials.data);

    setSpecialityname(specials.data.speciality.name);
    const distanceData = await clinicDistance();
    if (distanceData) {
      setAllDistances(distanceData.data.distance);
    }
  }, []);

  useEffect(async () => {
    if (tempKeyword.length < 3) return false;
    getDoctors();
  }, [tempKeyword]);
  useEffect(() => {
    if (tempKeyword.length === 0) {
      getDoctors();
    }
  }, [tempKeyword]);
  function getDoctors() {
    setEarlySlots(false)
    const delayDebounceFn = setTimeout(async () => {
      setLoading(true);
      const doctors = await doctorAvailability(
        sId,
        Id,
        keyword.length >= 3 ? keyword : ""
      );
      setDoctor(doctors.data.doctors);
      setAllDoctors(doctors.data.doctors);
      setLoading(false);
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }

  useEffect(async () => {
    if (filterModal) {
      getCities();
    } else {
      setAllCities([]);
      setHasMore(true);
      setCurrentCityPage(1);
    }
  }, [filterModal]);

  const onScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      if (scrollTop + clientHeight === scrollHeight) {
        getCities();
      }
    }
  };

  async function getCities() {
    if (currentCityPage && hasMore) {
      const cityList = await indianCities(1, currentCityPage);

      if (cityList && cityList.data) {
        setAllCities([...allCities, ...cityList.data.cities]);
        if (cityList.data.pagination.links.next) {
          let cPage = currentCityPage + 1;
          setCurrentCityPage(cPage);
          setHasMore(true);
        } else {
          setHasMore(false);
        }
      }
    }
  }

  async function filterDoctor(event) {
    event.preventDefault();

    if (
      distance ||
      (cities && cities.length) ||
      startDate ||
      endDate ||
      gender
    ) {
      let payLoad = {
        radius: distance,
        city: cities,
        gender,
        status: "1",
      };
      if (startDate && endDate) {
        payLoad["start_date"] = startDate;
        payLoad["end_date"] = endDate;
      }

      const response = await doctorAvailabilityByFilter(sId, payLoad);
      setDoctor(response.data.doctors);
      setFilterApplied(true);

      setFilterModal(false);
    } else {
      setFilterModal(false);
    }
  }

  const breadCrumbData = [
    { type: 1, url: "/", name: "Home" },

    {
      type: 1,
      url: location.pathname.startsWith("/book-consult/clinics/")
        ? "/book-consult/clinics"
        : "/book-consult/hospitals",
      name: "Book Consult",
    },
    ...(!location.pathname.startsWith("/book-consult/clinics/")
      ? [
          {
            type: 1,
            url: `/book-consult/hospitals/${
              hospitalInfo ? hospitalInfo.slug : ""
            }/${Id}`,
            name: hospitalInfo ? hospitalInfo.name : "",
          },
        ]
      : []),
    { type: 3, url: "", name: specialityName },
  ];
  const resetValues = () => {
    setCities([]);
    setStartDate(null);
    setEndDate(null);
    setGender(0);
    setAvailibilityType("");
    setDistance(null);
    setDoctor(allDoctors);
  };
  const [isEarlySlots,setEarlySlots] = useState(false)
  const fetchEarliestSlots = ()=>{
    setLoading(true)
    if(Id){
      doctorImmediateAvailability(sId, Id)
      .then(function (response) {
        let res = response.data;
        console.log('res: ', res);
        setEarlySlots(true)
        if (res && res.doctors && res.doctors.data) {
          setDoctor(res.doctors.data);
        }
    setLoading(false)

      })
      .catch(function (err) {
    setLoading(false)

      });
    }
  }
  return (
    <React.Fragment>
      <section>
        <Container>
          <BreadcrumbWithUrl breadcrumbs={breadCrumbData} />

          <Accordion defaultActiveKey="" className="mhc-mobile-tabs">
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                {specialInfo && specialInfo.speciality.name}
              </Accordion.Header>

              <Accordion.Body>
                <h3> {specialInfo && specialInfo.speciality.name}</h3>
                <p>
                  MyHealthcare is credited to be pioneer in the field of Cardiac
                  Surgery and Cardiology in southern India with initiation of
                  heart care program in mid 80's. This department offers
                  comprehensive cardiac care having flat panel Philips
                  catheterisation lab, dedicated coronary Care unit (ICCU),
                  finest modular OTs, CTVS ICU, top of the line Non Invasive
                  Technology including high end Echo cardiogrphy, stress test
                  (TMT) and Doppler and CT. A team of senior interventional
                  Cardiologists with round the clock (365x24) availability of
                  interventional Cardiologists, CAG and angioplasty.
                </p>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          <div className="d-flex align-items-center mt-4">
            <SearchTextWidget
              placeHolder={`Search doctors`}
              btnDisplay={true}
              setKeyword={setKeyword}
              searchClickHandler={searchButtonClick}
            />
            <span
              className="mhc-filter-btn"
              onClick={() => {
                if (!filterApplied) {
                  resetValues();
                }
                setFilterModal(true);
              }}
            >
              <img src={Fliter} />
            </span>
          </div>
          <div className="mhc-consult-type d-flex bd-highlight flex-column flex-lg-row align-items-start  align-items-lg-center mt-4 position-relative ">
            <div className="order-0 order-lg-0">
              <span className="fw-bold ">Consult Type</span>
            </div>
            <div className="order-2 order-lg-0">
              <ul className="list-unstyled mb-0 mt-4 mt-lg-0 d-flex align-items-center">
                <li>
                  <div className="form-check mt-1 ms-0 ms-lg-4">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      id="All"
                      value="0"
                      checked={consultType === "0"}
                      onChange={(e) => setConsultType(e.target.value)}
                    />
                    <label className="form-check-label" htmlFor="All">
                      All
                    </label>
                  </div>
                </li>
                <li>
                  <div className="form-check mt-1 ms-3 ms-sm-4">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      id="Virtual"
                      value="1"
                      checked={consultType === "1"}
                      onChange={(e) => setConsultType(e.target.value)}
                    />
                    <label className="form-check-label" htmlFor="Virtual">
                      Virtual
                    </label>
                  </div>
                </li>
                <li>
                  <div className="form-check mt-1 ms-3 ms-sm-4">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      id="Hospital"
                      value="2"
                      checked={consultType === "2"}
                      onChange={(e) => setConsultType(e.target.value)}
                    />
                    <label className="form-check-label" htmlFor="Hospital">
                      At Hospital/ Clinic
                    </label>
                  </div>
                </li>
              </ul>
            </div>

            <div className="ms-lg-auto order-1 order-lg-0">
              <Button
              type='link'
              style={{color:'white'}}
              onClick={isEarlySlots?getDoctors:fetchEarliestSlots}
                // to={`/book-consult/consult-doctor-earlist-slot/${Id}/${sId}`}
                className="btn btn-outline-primary rounded-1"
              >
                {isEarlySlots?'All Doctors':'Earliest Slots'}
              </Button>
            </div>
          </div>
          {loading ? (
            <>
              <div className="mhc-Loding text-center my-5">
                <img src={LodaingImage} alt="loader" />
              </div>
            </>
          ) : (
            <div className="row mt-4">
              <Doctors
                doctors={doctor}
                consultType={consultType}
                sId={sId}
                callingType={location.pathname.startsWith("/book-consult/clinics/")?'clinics':'hospitals'}
              />
            </div>
          )}
        </Container>
      </section>

      <FilterDoctorModal
        filterModal={filterModal}
        values={{
          gender,
          cities,
          startDate,
          endDate,
          distance,
        }}
        onHide={() => {
          setStartDate(null);
          setEndDate(null);
          setFilterModal(false);
        }}
        allCities={allCities}
        listInnerRef={listInnerRef}
        onScroll={() => onScroll()}
        allDistances={allDistances}
        changeDistance={(e, item) => {
          if (e.target.checked) setDistance(item.id)
          else setDistance(null);
        }}
        onAvailibilityChange={(e) => {
          if (e.target.checked) {
            const next2Days = new Date(Date.now() + 2 * 24 * 60 * 60 * 1000);
            setStartDate(dateFormatIntoDDMMYYYY(next2Days));
            setEndDate(dateFormatIntoDDMMYYYY(next2Days));
          }
        }}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        onGenderChange={(e, gender) => {
          setGender(gender);
        }}
        closeModal={() => setFilterModal(false)}
        filterDoctor={filterDoctor}
        onCityChange={(e, item) => {
          if (e.target.checked) {
            setCities([...cities, item.id]);
          } else {
            setCities((prevCities) => {
              return prevCities.filter((cityId) => cityId !== item.id);
            });
          }
        }}
        startDate={startDate}
        endDate={endDate}
        availabilityType={availabilityType}
        setAvailibilityType={(type) => {
          setAvailibilityType(type);
        }}
        resetValues={resetValues}
      />
    </React.Fragment>
  );
}
