import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router";
import { getAllBlogs, getLandingPageBySlug } from "../../actions/get-calls";
import { Markup } from 'interweave';
const LandingPage = () => {
  const { slug } = useParams();
  const [data, setData] = useState({});
  const fetchLandingPage = useCallback(async () => {
    if (slug) {
      const response = await getLandingPageBySlug(slug);
      console.log("response: ", response);
      if (response && response.data) {
        setData(response.data[0]);
        const style = document.createElement('style')
        const headScript = document.createElement('script')
        const bodyScript = document.createElement('script')
        style.append(response.data[0].css)
        headScript.append(response.data[0].header_script)
        bodyScript.append(response.data[0].javascript)
        document.head.appendChild(style)
        document.head.appendChild(headScript)
        document.body.appendChild(bodyScript)
        document.body.appendChild(style)
      }
    }
  }, [slug]);
  useEffect(() => {
    fetchLandingPage();
  }, [fetchLandingPage]);
  return (
    <div id="outerDiv">
      <div>
        <Markup content={data.body} /> 
      </div>
    </div>
  );
};

export default LandingPage;
