import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Card } from "react-bootstrap";

import Breadcrumb from '../../component/breadcrumb/index'
import SearchForm from '../../component/search/index'
import ServiceAvailability from '../../component/serviceavailability/index'
import RequestCallback from '../../component/request-callback/requestCallback'
import { carePackages } from "../../actions/get-calls";
import { searchTextAPI } from "../../actions/post-call";
import SearchFormWidget from "../../component/search/search-form-widget";
import { encodeString } from "../../constants/app-constants";
import BreadcrumbWithUrl from "../../component/breadcrumb/breadcrumb-with-url";
import SearchTextWidget from "../../component/search/search-text-widget";

const CarePackageCategories = () => {
    const [pincode, setPincode] = useState("");
    const [serviceable, setServiceable] = useState(false);
    const [categories, setCategories] = useState(null);
    const [isProcessing, setIsProcessing] = useState(false);
    const [loading, setLoading] = useState(false);
    const [tempKeyword, setTempKeyword] = useState('');

    const [keyword, setKeyword] = useState("");
    const [searchResult, setSearchedResult] = useState(null);

    const getCarePackages = async () => {
        setIsProcessing(true);
        let _categories = await carePackages();
        setIsProcessing(false);
        if (_categories && _categories.data && _categories.data.health_offers) {
            setCategories(_categories.data.health_offers);
        }
    }

    useEffect(async () => {
        if (serviceable) {
            if (pincode && (pincode.toString().length === 6)) {
                getCarePackages();
            }
        } else {
            setCategories(null);
        }
    }, [serviceable, pincode]);

    useEffect(async () => {
        if (tempKeyword === '') {
            getCarePackages()
        } else if (tempKeyword.length > 2) {
            setIsProcessing(true);
            const searchedData = await searchTextAPI(keyword, 'health_offers');

            if (searchedData.data.status === 200) {
                setIsProcessing(false);
                setCategories(searchedData.data.health_offers);
                setSearchedResult(searchedData.data.health_offers);
            }
        }
    }, [tempKeyword]);
    
    const breadCrumbData = [
        { type: 1, url: '/', name: 'Home' },
        { type: 3, name: 'Care Packages' },
    ];

    const searchClickHandler = () => {
        if (keyword.length > 2) {
            setTempKeyword(keyword)
        } else if (keyword.length === 0) {
            setTempKeyword('')
        }
    }
    return (
        <React.Fragment>
            <div className="container">
                <BreadcrumbWithUrl breadcrumbs={breadCrumbData} />

                <div className="row">
                    <div className="col-lg-6 ">
                        <h1 className="fs-4 mb-1">Care Packages</h1>

                    </div>
                    <div className="col-lg-6 mt-4 mt-lg-0">
                        <SearchTextWidget
                            placeHolder={`Search Packages`}
                            btnDisplay={true}
                            // searchType={['health_offers']}
                            // searchResult={searchResult}
                            searchClickHandler={searchClickHandler}
                            setKeyword={setKeyword} />
                    </div>
                </div>

                <div className="row position-relative mt-4 ">
                    <div className="col-lg-6">
                        <div className="d-flex justify-content-start">
                            <ServiceAvailability
                                pincode={pincode}
                                setPincode={setPincode}
                                serviceable={serviceable}
                                setServiceable={setServiceable}
                                setLoading={setLoading}
                                />
                        </div>
                    </div>
                    <div className="col-lg-6 text-left text-lg-end">
                        <RequestCallback position={false} />
                    </div>
                </div>
                {isProcessing ? <div className="mhc-Loding text-center my-5">
                    <img src="../loader.gif" alt="loader" />
                </div> : <div className="row mt-2 mt-lg-4">
                    {
                        categories ? categories.map((item, index) => (
                            <div className=" col-lg-4 col-md-6 col-sm-12 col-12 mb-4" key={index}>
                                <Card className="homecare-category-card">
                                    <Card.Body>
                                        <div className="row">
                                            <div className="col-12 d-flex justify-content-between">
                                                <div className="w-75">
                                                    <h6>{item.category_name.replaceAll("_", " ")}</h6>
                                                    <p className="text-secondary">Starting at INR {item.packages[0].discounted_price}</p>
                                                </div>
                                                <div className="w-25">
                                                    <Card.Img variant="top" className="image" src={item.image} alt={item.category_name.replaceAll("_", " ")} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row align-items-center justify-content-end">
                                            <div className="col-auto text-end">
                                                <Link className="btn btn-primary rounded-1 " to={`/care-packages/${item.category_name.toLowerCase().trim().replaceAll("_", "-")}/${item.id ? item.id : '_'}`}>Details</Link>
                                            </div>
                                        </div>

                                    </Card.Body>
                                </Card>
                            </div>
                        )) : <div className="col-12">
                                <div className="alert alert-danger text-center">
                                    No packages available!
                                </div>
                            </div>
                    }
                </div>}

            </div>
        </React.Fragment>
    )
}

export default CarePackageCategories;