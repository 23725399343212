import React, { useEffect, useLayoutEffect, useState } from "react";
import { Tabs, Tab, Modal, Accordion, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import { thyrocareDetail } from "../../actions/get-calls";
import lalpath from "../../assets/dust/lalpathlabs.webp";
import Breadcrumb from "../../component/breadcrumb";
import BreadcrumbWithUrl from "../../component/breadcrumb/breadcrumb-with-url";
const CarePackageDetail = () => {
    const [blogs,setBlogs] = useState([])
    const fetchBlogs = async () => {
      const response = await thyrocareDetail();
      if(response && response.data && Array.isArray(response.data)){
        setBlogs(response.data)
      }
      console.log('response: ===============', response);
    };
    useEffect(() => {
      fetchBlogs();
    }, []);
    useLayoutEffect(() => {
      window.scrollTo(0, 0);
    });
    const breadCrumbData = [
        { type: 1, url: "/", name: "Home" },
        { type: 2, name: 'Care Package' },
      ];
  return (
    <>
      <Container className="mhc-package-modal">
      <BreadcrumbWithUrl breadcrumbs={breadCrumbData} />

        <div className="d-flex flex-column flex-md-row justify-content-between align-items-start align-items-md-center  mb-4">
          <div>
            <h6 className="m-0">Aarogyam 1.2</h6>
            <p className="m-0">Profile Includes (61 Parameters)</p>
          </div>

          <div className="d-flex mt-3 mt-md-0 flex-column flex-md-row align-items-start align-items-md-center">
            <div className="me-0 me-md-3 mb-2 mb-md-0">
              <span>
                <del>INR 999</del>
              </span>{" "}
              <span class="text-primary ms-3">INR 699</span>
            </div>
            <img src={lalpath} className="test-icon" />
          </div>
        </div>
        <Accordion defaultActiveKey="" className="">
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              Liver Function Test (includes 11 tests)
            </Accordion.Header>
            <Accordion.Body>
              <ul className=" m-0">
                <li>Thyroid Stimulating Hormone</li>
                <li>Thyroxine - Total</li>
                <li>Triiodothyronine Total</li>
              </ul>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header>
              Liver Function Test (includes 11 tests)
            </Accordion.Header>
            <Accordion.Body>
              <ul className=" m-0">
                <li>Thyroid Stimulating Hormone</li>
                <li>Thyroxine - Total</li>
                <li>Triiodothyronine Total</li>
              </ul>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="2">
            <Accordion.Header>
              Liver Function Test (includes 11 tests)
            </Accordion.Header>
            <Accordion.Body>
              <ul className=" m-0">
                <li>Thyroid Stimulating Hormone</li>
                <li>Thyroxine - Total</li>
                <li>Triiodothyronine Total</li>
              </ul>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="3">
            <Accordion.Header>
              Liver Function Test (includes 11 tests)
            </Accordion.Header>
            <Accordion.Body>
              <ul className=" m-0">
                <li>Thyroid Stimulating Hormone</li>
                <li>Thyroxine - Total</li>
                <li>Triiodothyronine Total</li>
              </ul>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        <div className="w-100 text-center my-4">
          <Link to="/package-login" className="btn btn-primary rounded-1">
            Book Package
          </Link>
        </div>
      </Container>
    </>
  );
};

export default CarePackageDetail