import React, { useEffect, useState } from "react";
import {
  getAllBlogs,
  getPartnerFirstSection,
  getTextFromCMS,
} from "../../actions/get-calls";
import BreadcrumbWithUrl from "../../component/breadcrumb/breadcrumb-with-url";

const YourPartners = () => {
  const [partnerData, setPartnerData] = useState({});
  const fetchBlogs = async () => {
    const response = await getPartnerFirstSection();
    //   if(response && response.data && Array.isArray(response.data)){
    //     // setBlogs(response.data)
    //   }
    console.log("response: ", response);
    setPartnerData(response.data[0]);
  };
  useEffect(() => {
    fetchBlogs();
  }, []);
  const [content, setContent] = useState(null);
  const [contentAccordian, setContentAccordian] = useState(null);

  useEffect(async () => {
    // Content
    const _setContent = await getTextFromCMS(3, 2);
    console.log("_setContent: ", _setContent);
    if (_setContent) setContent(_setContent.data[0]);

    // Content
    const _setContentAccordian = await getTextFromCMS(3, 3);
    if (_setContentAccordian) setContentAccordian(_setContentAccordian.data);
  }, []);
  const breadCrumbData = [
    { type: 1, url: "/", name: "Home" },
    { type: 2, name: "For Partners" },
  ];
  return (
    <div>
      <section className="mhc-partner-banner">
        <div className="container">
          <BreadcrumbWithUrl breadcrumbs={breadCrumbData} />
          <div className="row">
            <div className="col-sm-7 col-md-5">
              <h1 className="mb-1">
                {partnerData.title}
                {/* Your partners in your{" "}
                <br className="d-block d-sm-none d-xl-block" />
                digital journey */}
              </h1>
              <div
                className="container"
                dangerouslySetInnerHTML={{ __html: partnerData.text }}
              ></div>
            </div>
          </div>
        </div>
      </section>
      {content ? (
        <section className="mhc-partner-padding">
          <div
            className="container"
            dangerouslySetInnerHTML={{ __html: content.text }}
          ></div>
        </section>
      ) : (
        ""
      )}{" "}
    </div>
  );
};

export default YourPartners;
