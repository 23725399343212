import React, { useState, useEffect } from "react";
import { Tabs, Tab, Modal, Accordion } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import { Link } from "react-router-dom";
import {
  configurations,
  thyrocareDetail,
  thyrocareList,
} from "../../../actions/get-calls";
import {
  clearPackageDetailReducer,
  setPackageDetailReducer,
} from "../../../redux/slice/package-detail";

import {
  setVendorReducer,
  clearVendorReducer,
} from "../../../redux/slice/vendor";

const BookPackage = ({ keyword = "", pincode, serviceable, type ,setLoading , loading,url}) => {
  const dispatch = useDispatch();
  const vendorId = useSelector((state) => state.vendor.value);
  const bookingSource = useSelector((state) => state.bookingSource.value);

  const [diagnosticCategory, setDiagnosticCategory] = useState(null);
  const [selectedCategoryName, setSelectedCategoryName] = useState(null);
  const [diagnosticCategories, setDiagnosticCategories] = useState(null);
  const [thyroProducts, setThyroProducts] = useState(null);
  const [packageDetail, setPackageDetail] = useState(null);
  const [showPackageDetail, setShowPackageDetail] = useState(false);
  // const [loading, setLoading] = useState(false);

  const [compareId1, setCompareId1] = useState(null);
  const [compareId2, setCompareId2] = useState(null);

  useEffect(async () => {
    const configuration = await configurations();
    if (configuration) {
      setDiagnosticCategories(configuration.data.diagnostics_categories);
      setDiagnosticCategory(configuration.data.diagnostics_categories[0].id);
      setCategoryName(configuration.data.diagnostics_categories[0].id, configuration.data.diagnostics_categories);
    }

    if (bookingSource && bookingSource !== "package-booking") {
      dispatch(clearVendorReducer());
    }
  }, []);

  useEffect(() => {
    setCompareId1(null);
    setCompareId2(null);
    async function fetchPackages() {
      if (type === 2) {
        if (serviceable && pincode.toString().length === 6 && (keyword === '' || keyword.length>2)) {
          const delayDebounceFn = setTimeout(async () => {
            setLoading(true);
            const _thyroProducts = await thyrocareList(
              type,
              diagnosticCategory,
              pincode,
              1,
              keyword
            );
            if (_thyroProducts && _thyroProducts.data) {
              let packageLists = [];
              packageLists = keyword
                ? _thyroProducts.data.PACKAGES
                : _thyroProducts.data.TESTS;
              if (vendorId) {
                packageLists = packageLists.filter(
                  (p) => p.vendor_id === vendorId
                );
              }
              setThyroProducts(packageLists.length !== 0 ? packageLists : null);
            }
            setLoading(false);
          }, 500);

          return () => clearTimeout(delayDebounceFn);
        }
      }
    }
    fetchPackages();
  }, [type, pincode, serviceable, diagnosticCategory,keyword,vendorId]);

  async function handleShow(id) {
    setShowPackageDetail(true);
    const _thyroProduct = await thyrocareDetail(id, pincode);

    if (_thyroProduct && _thyroProduct.data && _thyroProduct.data.details) {
      let modifiedChild = _thyroProduct.data.details[0].childs.reduce(function (
        r,
        a
      ) {
        r[a.group_name] = r[a.group_name] || [];
        r[a.group_name].push(a);
        return r;
      },
      Object.create(null));

      let sortedChild = [];
      for (var x in modifiedChild) {
        sortedChild.push(modifiedChild[x]);
      }

      let mChild = { mData: sortedChild };
      _thyroProduct.data.details[0] = {
        ..._thyroProduct.data.details[0],
        ...mChild,
      };
    }
    setPackageDetail(_thyroProduct.data);
  }

  function handleClose() {
    setShowPackageDetail(false);
    setPackageDetail(null);
  }

  function setCompare(e) {
    if (e.target.checked) {
      if (!compareId1) {
        setCompareId1(parseInt(e.target.value));
      } else {
        setCompareId2(parseInt(e.target.value));
      }
    } else {
      if (compareId1 === parseInt(e.target.value)) {
        setCompareId1(null);
      } else {
        setCompareId2(null);
      }
    }
  }

  function setForwardData(id, packageDetail) {
    dispatch(setVendorReducer(id));
    dispatch(clearPackageDetailReducer());
    dispatch(setPackageDetailReducer({...packageDetail,pincode}));
  }

  function setCategoryName(id, arr = null) {
    let currentIndex = -1;
    if(arr === null) {
      currentIndex = diagnosticCategories && diagnosticCategories.map(t => t.id).indexOf(parseInt(id));
      if (currentIndex !== -1) {
        setSelectedCategoryName(diagnosticCategories[currentIndex].name);
      }
    } else {
      currentIndex = arr && arr.map(t => t.id).indexOf(parseInt(id));
      if (currentIndex !== -1) {
        setSelectedCategoryName(arr[currentIndex].name);
      }
    }

  }

  return (
    <>
      <div className="Package-btn-tabs mt-0 mt-lg-3">
        <Tabs
          activeKey={diagnosticCategory}
          onSelect={(e) => {
            setLoading(true);
            setDiagnosticCategory(e);
            setCategoryName(e);
          }}
        >
          {diagnosticCategories &&
            diagnosticCategories.map((mainItem) => (
              <Tab
                eventKey={mainItem.id}
                title={mainItem.name}
                key={mainItem.id}
              >
                {!loading ? (
                  <div className="row mt-2 mb-4">
                    {thyroProducts ? (
                      thyroProducts.map((item) => {
                        
                       return (
                        
                        <div className="col-lg-6 col-xl-4 mb-4" key={item.id}>
                          <div className="mhc-package-tab ">
                            <div className="d-flex justify-content-between align-items-start ">
                              <div>
                                <p className="m-0 packg-tittle">{item.name}</p>
                                <span className="packg-parm">
                                  Includes {item.testCount} Tests{" "}
                                  <i
                                    className="mhc-icon icon-mhc-info cursor-pointer"
                                    onClick={() => handleShow(item.id)}
                                  ></i>
                                </span>
                              </div>
                              <div>
                                {item.rates.pay_amt1 !== item.rates.pay_amt && (
                                  <span className="text-primary text-nowrap ms-2">
                                    {(
                                      ((item.rates.pay_amt1 -
                                        item.rates.pay_amt) /
                                        item.rates.pay_amt1) *
                                      100
                                    ).toFixed(0)}
                                    % OFF
                                  </span>
                                )}
                              </div>
                            </div>
                            <div className="d-flex justify-content-between align-items-center mt-2">
                              <div>
                                {item.rates.pay_amt1 !== item.rates.pay_amt && (
                                  <span className="me-3">
                                    <del>INR {item.rates.pay_amt1}</del>
                                  </span>
                                )}
                                <span className="text-primary">
                                  INR {item.rates.pay_amt}
                                </span>
                              </div>
                              <img src={item.image} className="pacg-icon" />
                            </div>
                            <div className="d-flex justify-content-between align-items-center mt-3">
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id={item.name.trim() + item.id}
                                  value={item.id}
                                  disabled={
                                    compareId1 &&
                                    compareId2 &&
                                    item.id !== compareId1 &&
                                    item.id !== compareId2
                                  }
                                  onChange={(e) => setCompare(e)}
                                />
                                <label className="form-check-label d-block d-sm-inline">
                                  Compare Now
                                </label>
                              </div>
                              <Link
                                onClick={() =>
                                  setForwardData(item.vendor_id, item)
                                }
                                to={`/book-package-or-test/packages/${item.slug}/package-booking`}
                                className="btn btn-primary rounded-1"
                              >
                                Book
                              </Link>
                            </div>
                          </div>
                        </div>
                      )
                              })
                    ) : (
                      <div className="col-12">
                        <div className="alert alert-danger text-center">
                          No packages available!
                        </div>
                      </div>
                    )}

                    {compareId1 && compareId2 && (
                      <div className="col-12 text-center">
                        <Link
                          to={`/package-comparison/${selectedCategoryName ? selectedCategoryName.toLowerCase().trim().replaceAll(" ", "-") : "-"}/${compareId1}/${compareId2}`}
                          className="btn btn-primary rounded-1"
                        >
                          Compare
                        </Link>
                      </div>
                    )}
                  </div>
                ) : (
                  <div className="mhc-Loding text-center my-5">
                    <img src="../loader.gif" alt="loader" />
                  </div>
                )}
              </Tab>
            ))}
        </Tabs>
      </div>

      <Modal
        show={showPackageDetail}
        onHide={() => handleClose()}
        size="xl"
        className="mhc-package-modal"
      >
        <Modal.Header closeButton>
          {packageDetail && (
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <h6 className="m-0">{packageDetail.name}</h6>
                <p className="m-0">
                  Profile Includes ({packageDetail.details[0].testCount}{" "}
                  Parameters)
                </p>
              </div>
              <div>
                <img
                  src={packageDetail.details[0].diagnosis_logo}
                  className="pacg-icon"
                />
              </div>
            </div>
          )}
        </Modal.Header>
        <Modal.Body>
          {packageDetail ? (
            <Accordion defaultActiveKey={0}>
              {packageDetail.details[0].mData.map((xItem, index) => {
                return (
                  <Accordion.Item eventKey={index} key={index}>
                    <Accordion.Header>
                      {xItem[0].group_name} (includes {xItem.length} tests)
                    </Accordion.Header>
                    <Accordion.Body>
                      <ul className="m-0">
                        {xItem &&
                          xItem.map((mItem, mIndex) => {
                            return <li key={mIndex}>{mItem.name}</li>;
                          })}
                      </ul>
                    </Accordion.Body>
                  </Accordion.Item>
                );
              })}
            </Accordion>
          ) : (
            <div className="mhc-Loding text-center my-5">
              <img src="../loader.gif" alt="loader" />
            </div>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default BookPackage;
