import React, { useCallback, useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { useSelector } from "react-redux";
import { searchindianCities, getPurchaseDevice } from "../../actions/get-calls";
import img1 from "../../assets/dust/Image 30.webp";
import img2 from "../../assets/dust/Image 31.webp";
import Breadcrumb from "../../component/breadcrumb";
import BreadcrumbWithUrl from "../../component/breadcrumb/breadcrumb-with-url";
const PurchaseDevice = () => {
  const [page, setPage] = useState(1);
  const [devices, setDevices] = useState([]);
  const callToken = useSelector((state) => state.callToken.value);
  const switchToken = useSelector((state) => state.switchToken.value);
  const fetchDevices = useCallback(async () => {
    const response = await getPurchaseDevice(
      page,
      switchToken ? switchToken : callToken
    );
    console.log("response:devices ", response);
    if (response && response.data && response.data.data) {
      setDevices(response.data.data.product || []);
    }
  }, [switchToken, page, callToken]);
  useEffect(() => {
    fetchDevices();
  }, [fetchDevices]);
  const breadCrumbData = [
    { type: 1, url: "/", name: "Home" },
    { type: 2, name: "Purchase Device" },
  ];
  return (
    <Container>
      {/* <Breadcrumb url="" chlidrenUrl="" text="purchase device" /> */}
      <BreadcrumbWithUrl breadcrumbs={breadCrumbData} />
      {!callToken && <div style={{color:'#EA5D6E', textAlign:'center'}}>Please Login to purchase devices</div>}
      {(callToken || switchToken) && <h1 className="fs-4">Purchase Device</h1>}
      <div className="row py-4">
        {devices.map((device) => {
          return (
            <div className="col-xxl-4 col-xl-5 col-lg-6">
              <div className="purchase-Device-card">
                <div className="d-flex align-items-center     justify-content-center">
                  <div>
                    <img
                      src={device.product_image}
                      alt=""
                      className="device-img"
                    />
                  </div>
                  <div className="ms-5">
                    <img src={img2} alt="" className="device-logo" />
                    <p>{device.product_name}</p>
                    <a
                      href={device.product_url}
                      target="_blank"
                      type="submit"
                      className="btn btn-primary"
                    >
                      Click Here
                    </a>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>

      <br className="d-none d-xl-block" />
      <br className="d-none d-xl-block" />
      <br className="d-none d-xl-block" />
      <br className="d-none d-xl-block" />
      <br className="d-none d-xl-block" />
      <br className="d-none d-xl-block" />
      <br className="d-none d-xl-block" />
      <br className="d-none d-xl-block" />
      <br className="d-none d-xl-block" />
      <br className="d-none d-xl-block" />
      <br className="d-none d-xl-block" />
      <br className="d-none d-xl-block" />
      <br className="d-none d-xl-block" />
      <br className="d-none d-xl-block" />
      <br className="d-none d-xl-block" />
    </Container>
  );
};
export default PurchaseDevice;
