import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { indianPincode } from "../../actions/get-calls";
import { selectedPincodeReducer } from "../../redux/slice/selected-pincode";

const ServiceAvailability = ({
    pincode,
    serviceable,
    setPincode,
    setServiceable,
    setLoading
}) => {
    const dispatch = useDispatch();
    const selectedPincode = useSelector((state) => state.selectedPincode.value);
    const [pincodeChecking, setPincodeChecking] = useState(false);

    useEffect(async () => {
        if (selectedPincode) {
            setPincode(selectedPincode);
        }
    }, [selectedPincode]);

    useEffect(() => {
        if (pincode && pincode.toString().length === 6) {
            setPincodeAndStatus(pincode);
            dispatch(selectedPincodeReducer(pincode));
        }
    }, [pincode]);

    async function setPincodeAndStatus(value) {
        setServiceable(false);
        setPincodeChecking(true);
        setLoading(true);
        const _detail = await indianPincode(value, null);

        if (_detail && _detail.data) {
            setServiceable(_detail.data.status);
            if (!_detail.data.status) {
                setLoading(false);
            }
            setPincodeChecking(false);
        }
    }

    return (
        <React.Fragment>
            <div className="d-flex justify-content-end flex-wrap">
                <div className="d-flex flex-column flex-lg-row">
                    <div>
                        <p className="m-0 pt-0 pt-lg-2">Check service availability</p>
                    </div>
                    <div className="ms-0 ms-lg-3 my-3 my-lg-0">
                        <div className="form-floating mb-0">
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Pincode"
                                maxLength="6" max="6" min="6"
                                value={pincode}
                                onChange={(e) => { setPincodeChecking(true); setPincode(e.target.value) }}
                            />
                            <label htmlFor="name">Pin Code <sup>*</sup></label>
                        </div>
                        {
                            pincode && (pincode.toString().length === 6) ?
                                !pincodeChecking ?
                                    <p className="mt-1 m-0 text-primary">Your location is {serviceable ? 'servicable' : 'not servicable'}.</p> : ""
                                : <p className="mt-1 m-0 text-primary">Please enter valid 6-digit pincode.</p>
                        }
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default ServiceAvailability;