import React, { useCallback, useEffect, useState } from 'react';
import { getImageFromCMS } from '../../actions/get-calls';

const SixthSection = () => {
    const [data,setData] = useState([])
    const getData = useCallback(async()=>{
        const _setContent = await getImageFromCMS(3, 6);
        console.log("_setContent:Sixth ", _setContent);
        if (_setContent) setData(_setContent.data||[]);
    },[])
    useEffect( () => {
        // Content
        getData();
      }, []);
  return (
    <section className="mhc-partner-padding">
        <div className="container">
          <h3 className="fs-3 text-center">
            MyHealthcare services provided to the hospitals
          </h3>
          <div className="row mt-4 mt-lg-5 justify-content-center">
            {data.map((item)=>{
                return             <div className="col-6 col-sm-6 col-md-4 col-lg-3 ">
                <div className="partner-services">
                  <img src={item.image} alt="hospitalInformation" />
                  <h6>
                    {item.title}
                  </h6>
                </div>
              </div>
            })}
          </div>
        </div>
      </section>
  )
}

export default SixthSection