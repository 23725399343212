import React, { useCallback, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { getImageFromCMS, getTextFromCMS } from '../../actions/get-calls';
import partnerone from "../../assets/images/partner-one.webp";

const FourthSection = () => {
    const [data,setData] = useState({})
    const getData = useCallback(async()=>{
        const _setContent = await getImageFromCMS(3, 4);
        console.log("_setContent:FOURTH ", _setContent);
        if (_setContent) setData(_setContent.data[0]||{});
    },[])
    useEffect( () => {
        // Content
        getData();
      }, []);
  return (
    <section className="mhc-partner-padding">
    <div className="container">
      <div className="row justify-content-between align-items-center">
        <div className="col-lg-6">
          <p className="m-0 text-primary fw-bold">
            
            MyHealthcare for</p>
          <h3 className="fs-3">{data.title}</h3>
          <div
                className="container"
                dangerouslySetInnerHTML={{ __html: data.text }}
              ></div>
          <Link className="btn btn-primary mt-0 mt-md-4" to="/faq">
            Frequently Asked Questions{" "}
            <i className="mhc-icon icon-right"></i>
          </Link>
        </div>
        <div className="col-lg-5 mt-4 mt-lg-0">
          <img src={data.image} alt="partnerone" className="img-fluid" />
        </div>
      </div>
    </div>
  </section>
  )
}

export default FourthSection